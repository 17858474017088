@font-face {
    font-family: Gilroy-Bold;
    src: url('../../Assets//fonts/Gilroy-Bold.ttf');
}


.sidebar{
    min-height: 100vh;
    width: 239px;
    min-width: 239px;
    background: #EDFDFF;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.16);
}

 .logo-section{
    display: flex;
 }

 .logo-section .img-2{
      margin-left: -10px;
 }

.menu-section .nav-text{
    font-size: 18px;
    line-height: 21px;
}

.menu-section .active p, .menu-section .active svg, .menu-section .active svg path{
    color: #12C4E0 !important;
    font-weight: bold !important;
    fill:  #12C4E0;
}

.nav-item {
    cursor: pointer;
    align-items: center;
}

.nav-item svg{
    width: 20px;
}

.nav-item p{
    color: #A1A1A1;
}

.nav-item:hover p{
    color: #12C4E0  ;
    font-family: 'Gilroy-Bold';
}
.nav-item:hover svg{
    fill:  #12C4E0;
}
.nav-item:hover svg path{
    fill:  #12C4E0;
}