.top-nav {
    height: 101px;
    max-width: 1081px;
}

.top-nav h2 {
    font-size: 36px;
    line-height: 42px;
    color: #121212;
}


/* ==== RIGHT SIDE ITEMS ==== */

.search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border: 2px solid #12C4E0;
    border-radius: 11px;
}


.notification-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: #12C4E0;
    border-radius: 11px;
}

.user-box {
    width: 60px;
    height: 60px;

}

/* ======= Drop Down ===== */

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 390px;
    height: 232px;
    right: 0;
    top: 65px;
    background: #FFFFFF;
    box-shadow: 0px 5px 27px rgba(0, 0, 0, 0.11);
    border-radius: 3px;
    /* padding: 12px 16px; */
    z-index: 1;
}

.dropdown-content p{
    font-size: 16px;
}

.dropdown:hover .dropdown-content {
    display: block;
}
/* Mobile Side Bar */
.mobile-sidebar{
   display: none;
    position: fixed;
    top: 0;
    right: 800px;
    z-index: 200;
    background-color:#EDFDFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    transition: all 1s ease !important;
}

.menu-active {
    right: 0;
    width: 100%;
    transition: all 1s ease !important;
}
@media (max-width:767px){
    .mobile-sidebar{
        display: block;
       
    }
    .top-nav h2 {
        font-size: 26px;
    }
    
}
