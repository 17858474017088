/* =========== MAIN CONTAINER ============ */

.processed-container {
    margin: auto;
    width: 1045px;
    height: 652px;
    left: 318px;
    top: 214px;
    background: #FFFFFF;
    box-shadow: 0px 4px 21px 12px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}



/* ====== CONTAINER HEAD  ======== */

.table-head h2 {
    font-size: 24px;
    line-height: 28px;
    color: #121212;
}

.divider-line {
    background-color: #CDCDCD;
}

.table-search-box {
    width: 268px;
    height: 46px;
    left: 1011px;
    top: 239px;
    background: #FFFFFF;
    border: 0.5px solid #12C4E0;
    border-radius: 11px;
}

.table-search-box ::placeholder {
    color: #C8C7C7;
}

.option-btn {
    text-align: center;
    width: 48px;
    height: 48px;
    left: 1289px;
    top: 239px;
    background: #FFFFFF;
    border: 1px solid #12C4E0;
    border-radius: 11px;
}


/* ====== TABLE   ======== */

.processed-table tbody {
    max-height: 477px;
    overflow: scroll;
}

.processed-table body {
    text-align: left;
    white-space: nowrap !important;
}

.processed-table tr {
    height: 51px;
}

.processed-table tbody tr:nth-child(even) {
    background: #F7F5F5;
}


.processed-table th {
    font-size: 18px;
    line-height: 21px;
}

.processed-table td {
    white-space: nowrap !important;
    font-size: 15px;
    line-height: 18px;
    min-width: 100px;
}

.processed-table .user-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    border-radius: 50%;
}

.trash-circle {
    width: 23px;
    height: 23px;
    background: #efefef;
    cursor: pointer;
}

.table-btn{
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #069425;
    width: 135px;
    height: 26px;
    background: #C1FFE1;
    border-radius: 5px;
}

.btn-completed {
    color: #069425;
    background: #C1FFE1;
}
.btn-pending {
    color: #CE7207;
    background: #FFCDA3;
}

.btn-cancel{
    color:#F80D0D;
    background: #FFACAB;
}
.table-container{
    overflow-x: auto;
}

@media (max-width:990px){
    .processed-container{
        width: 100%;
    }
}

@media (max-width:660px){
    .table-search-box{
        width: 230px;
    }
}

/* DropDown */
.dropdown-active{
    display: block !important;
}
.hidden-dropdown{
    display: none !important;
}
.text-cancel{
    font-size: 16px !important;
}

.dropdown-animated {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }