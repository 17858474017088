.App {
  background-color: #EDFDFF;
  min-height: 100vh;
}

.file-input{
  display: none;
}
.set-filter{
  color: #12C4E0;
  font-family: Gilroy-Bold;
}
.search-input-box{
  width: 90%;
  height: 46px;
  background: #FFFFFF;
  border: 0.5px solid #12C4E0;
  border-radius: 11px;
}
.ql-editor{
  min-height: 250px;
}
@media (min-width:1025px) and (max-width:1300px){
  .responsive-container{
    width: 100% !important;
  }
  .responsive-inner-container{
    width: 97%;
  }
}


@media (min-width:991px) and (max-width:1300px){
  .responsive-dashboard-container{
    width: 100% !important;
  }
  .responsive-dashboard-inner-container{
    width: 97%;
  }
}

@media (min-width:1024px){
  .profile-bg{
    width: 1142px;
    height: 225px;
  }
}
@media (min-width:1350px){
  .profile-bg{
    width: 100%;
    height: 225px;
  }
}

@media (min-width:1024px) and (max-width:1350px){
  .profile-field-container{
    margin-left: 0 !important;
    width: 100%;
  }
  .about-field{
    width: 100% !important;
  }
  .profile-field{
    width: 360px;
  }
}
@media (min-width:1200px) and (max-width:1350px){

  .profile-field{
    width: 400px;
  }
}

@media (min-width:1250px){
  .articles-text{
    width: 718px;
  }
}
