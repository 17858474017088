@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {
    .help-btn {
      @apply w-[123px] h-[48px] rounded-[5px] text-white  bg-primary font-g-regular  text-[18px]  text-center
    }
    .warning-btn{
      @apply w-[123px] h-[48px] rounded-[5px] text-[#ED785E]  bg-warning font-g-regular  text-[18px]  text-center
    }
    .block-btn{
      @apply w-[123px] h-[48px] rounded-[5px] text-[#F80D0D]  bg-danger font-g-regular  text-[18px]  text-center
    }
    .bugs-btn{
      @apply w-[123px] h-[48px] rounded-[5px] text-primary  bg-lightBlue font-g-regular  text-[18px]  text-center
    }
    .callback-btn{
      @apply w-[123px] h-[48px] rounded-[5px] text-[#069425]  bg-[#C1FFE1] font-g-regular  text-[18px]  text-center
    }
    
  }


@font-face {
    font-family: Gilroy-Bold;
    src: url('./Assets/fonts/Gilroy-Bold.ttf');
}

@font-face {
    font-family: Gilroy-Medium;
    src: url('./Assets/fonts/Gilroy-Medium.ttf');
}

@font-face {
    font-family: Gilroy-Regular;
    src: url('./Assets/fonts/Gilroy-Regular.ttf');
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .custom-scroll-bar {
    scrollbar-width: thin;
    scrollbar-color: #12C4E0 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .custom-scroll-bar::-webkit-scrollbar {
    width: 12px;
  }

  .custom-scroll-bar::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 50px;
  }

  .custom-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #12C4E0;
    border-radius: 9px;
    border: 3px solid #ffffff;
  }


  .ck.ck-editor__main>.ck-editor__editable{
    height: 60 !important;
    width: 432px !important;
    margin-top: 5px;
  }

  
  